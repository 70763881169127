import roomLayout from "../images/room-layout.png"
import MobileroomLayout from "../images/mobile-room-layout.jpg"
import floor3HeroImg from "../images/floor3-room.jpg"
import floor2HeroImg from "../images/floor2-room.jpg"
import floor1HeroImg from "../images/floor1-room.jpg"
import floor3Slide1 from "../images/floor3-slide-1.jpg"
import floor3Slide2 from "../images/floor3-slide-2.jpg"
import floor3Slide3 from "../images/floor3-slide-3.jpg"
import floor3Slide4 from "../images/floor3-slide-4.jpg"
import floor3Slide5 from "../images/floor3-slide-5.jpg"
import floor3Slide6 from "../images/floor3-slide-6.jpg"
import floor2Slide1 from "../images/floor2-slide-1.jpg"
import floor2Slide2 from "../images/floor2-slide-2.jpg"
import floor2Slide3 from "../images/floor2-slide-3.jpg"
import floor2Slide4 from "../images/floor2-slide-4.jpg"
import floor2Slide5 from "../images/floor2-slide-5.jpg"
import floor2Slide6 from "../images/floor2-slide-6.jpg"
import floor1Slide1 from "../images/floor1-slide-1.jpg"
import floor1Slide2 from "../images/floor1-slide-2.jpg"
import floor1Slide3 from "../images/floor1-slide-3.jpg"
import floor1Slide4 from "../images/floor1-slide-4.jpg"
import floor1Slide5 from "../images/floor1-slide-5.jpg"
import floor1Slide6 from "../images/floor1-slide-6.jpg"

export const roomsData = {
  floor3: {
    name: "Ebene3",
    shorName: "Ebene3",
    pageUrl: "/rooms/floor3",
    heroImg: floor3HeroImg,
    description:
      "Unsere Penthouse Ebene verzaubert nicht nur durch ihre besondere Großzügigkeit und die offenen Räume, sondern vor allem durch den fantastischen Ausblick auf die Bucht von Hout Bay. Entspannen Sie sich auf der wunderbaren Terrasse oder nehmen Sie ein Bad im Marmorbadezimmer. Tauchen Sie ein & entspannen Sie sich bei dem magischen Licht Südafrikas.",
    ShowAvailability: true,
    roomDetail: [
      "130 qm (inkl Terrasse)",
      "2 Doppelbettzimmer mit Bad en suite",
      "Privatbalkon mit Meerblick",
      "Waschmaschine und Trockner",
      "Zugang zum Hauspool & zur Holzterasse",
      "Privatbalkon & Terrasse inkl Weber Grill",
      "Weber Grill & Nespresso Kaffeemaschine ",
      "WLAN, AppleTV Box, Smart TV mit deutschen Sendern & Bluetooth Musikbox",
    ],
    slider: {
      imgUrl: [
        floor3Slide1,
        floor3Slide2,
        floor3Slide3,
        floor3Slide4,
        floor3Slide5,
        floor3Slide6,
      ],
    },
    roomDiagram: roomLayout,
    roomDiagramMobile: MobileroomLayout,
  },
  floor2: {
    name: "Ebene2",
    shorName: "Ebene2",
    pageUrl: "/rooms/floor2",
    heroImg: floor2HeroImg,
    description:
      "Lassen Sie sich von der modernen afrikanischen Atmosphäre in den Bann ziehen. Ruhige Schlafzimmer, eine Kochinsel und die lange Tafel in der Glasveranda mit Blick auf die Bucht von Hout Bay verzaubert innerhalb kürzester Zeit. Der perfekte Ort um Südafrika zu erleben.",
    roomDetail: [
      "180 qm (inkl. Terrasse)",
      "",
      "2 geräumige Schlafzimmer mit Doppelbetten und jeweils Bad En Suite ",
      "Penthousecharakter durch Terrasse mit Meerblick",
      "Zugang zum beheizten Hauspool & zur großzügigen Holzterrasse mit Meerblick",
      "großer Esstisch für 12 Personen mit Meerblick",
      "große Kochinsel & Weber Grill",
      "WLAN, AppleTV Box, Smart TV mit deutschen Sendern & Bluetooth Musikbox",
      "Nespresso Kaffeemaschine",
    ],
    slider: {
      imgUrl: [
        floor2Slide1,
        floor2Slide2,
        floor2Slide3,
        floor2Slide4,
        floor2Slide5,
        floor2Slide6,
      ],
    },
    roomDiagram: roomLayout,
    roomDiagramMobile: MobileroomLayout,
  },
  floor1: {
    name: "Ebene1",
    shorName: "Ebene1",
    pageUrl: "/rooms/floor1",
    heroImg: floor1HeroImg,
    description:
      "Eine Ebene voller Charakter zum Genießen & Entspannen. Lassen Sie es sich im Apartment mit Naturelementen gut gehen und kühlen Sie sich draußen im kleinen Privatpool ab. Das Kingsize Bett und das großzügige Marmorbad mit Blick auf die Bucht runden das luxuriöse Gefühl der Ebene ab.",
    roomDetail: [
      "130 qm (inkl Terrasse)",
      "2 Doppelbettenzimmer & 1 Etagenbettzimmer mit jeweils Bad en suite",
      "Waschmaschine und Trockner",
      "Zugang zum Hauspool & zur Holzterasse",
      "kleiner Privatpool & - terrasse mit Meerblick",
      "Weber Grill & Nespresso Kaffeemaschine ",
      "WLAN, AppleTV Box, Smart TV mit deutschen Sendern & Bluetooth Musikbox",
    ],
    slider: {
      imgUrl: [
        floor1Slide1,
        floor1Slide2,
        floor1Slide3,
        floor1Slide4,
        floor1Slide5,
        floor1Slide6,
      ],
    },
    roomDiagram: roomLayout,
    roomDiagramMobile: MobileroomLayout,
  },
}
