import { Box, compose, palette, SxProps, spacing, styled } from "@mui/system"
import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import { Container, Theme } from "@mui/material"

interface data {
  imgUrl: string[]
  sx?: SxProps
}
export const ReactCarousel: React.FC<data> = ({ imgUrl, ...props }) => {
  const [scrolled, setScrolled] = React.useState(false)

  const getElementY = () => {
    const carousel = document.getElementById("Carousel-section")
    if (carousel) {
      const carouselData = carousel.getBoundingClientRect()
      console.log("carouselData.y", carouselData.y)
      return carouselData.y
    }
  }

  const getElementHeight = () => {
    const carousel = document.getElementById("Carousel-section")
    if (carousel) {
      const carouselData = carousel.getBoundingClientRect()
      console.log("carouselData.y", carouselData.y)
      return carouselData.height
    }
  }

  // change state on scroll
  React.useEffect(() => {
    const handleScroll = () => {
      if (window) {
        const isScrolled =
          window.scrollY > getElementY() &&
          window.scrollY <
            getElementY() + window.innerHeight + getElementHeight()
        if (isScrolled !== scrolled) {
          setScrolled(!scrolled)
        }
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])
  return (
    <Box
      sx={{
        padding: "136px 0 160px",
        bgcolor: "#E0E2DF",
        "@media screen and (max-width: 767px)": {
          padding: "60px 0 73px",
        },
        ".carousel.carousel-slider": {
          overflow: "visible",
          ".control-dots": {
            display: "flex",
            width: "100%",
            justifyContent: "stretch",
            gap: "12px",
            margin: 0,
            bottom: -20,
            "@media screen and (max-width: 767px)": {
              bottom: -11,
            },
            li: {
              flex: 1,
              borderRadius: "0 !important",
              margin: "0 !important",
              height: "2px !important",
              boxShadow: "none !important",
              outline: "none !important",
              background: "#BBBBBB",
              opacity: "1 !important",
              "&.selected": {
                backgroundColor: "#464646",
                boxShadow: "none !important",
                outline: "none !important",
              },
            },
          },
        },
        ...props.sx,
        ".home-slide-image": {
          height: "600px",
          objectFit: "cover",
          "@media screen and (max-width: 767px)": {
            height: "217px",
          },
        },
      }}
    >
      <Container maxWidth="lg" data-active={scrolled} id="Carousel-section">
        <Carousel
          autoPlay={scrolled}
          emulateTouch
          infiniteLoop
          interval={5000}
          transitionTime={1000}
          showThumbs={false}
          stopOnHover={false}
          showArrows={false}
          showStatus={false}
        >
          {imgUrl.map((url, i) => (
            <div key={`slide-${i}`}>
              <img className="home-slide-image" src={url} alt="img" />
            </div>
          ))}
        </Carousel>
      </Container>
    </Box>
  )
}
